import styled from "styled-components";

export const Content = styled.div`
    height: 50%;
    display: flex;
    align-items: end;
    width: 100%;
    .codigo{
        height: 30%;
        width: 20%;
        display: flex;
        align-items: start;
        justify-content: center;
        border: 1px solid black;
        border-radius: 5px;
    }
    div{
        margin: 15px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-contet: space-around;
        width: 100%;
        height: 100%;
    }
    .div-descri{
        display: flex;
        flex-direction: row;
        width: 100%;
        input{
            width: 80%;
            height: 24px;
        }
    }
    
`

